import { FC } from "react";
import { Link } from "react-router-dom";

const ErrorPage: FC = () => {
  return (
    <>
      <div className="col-lg-12">
        {/* begin::Title */}
        <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
        {/* end::Title */}

        {/* begin::Text */}
        <div className="fw-semibold fs-6 text-gray-500 mb-7">
          We can't find that page.
        </div>
        {/* end::Text */}

        {/* begin::Illustration */}
        <div className="mb-3">
          <img
            src="/public/media/images/5-dark.png"
            className="mw-100 mh-300px theme-light-show"
            alt=""
          />
        </div>
        {/* end::Illustration */}

        {/* begin::Link */}
        <div className="mb-0">
          <Link to="/signout" className="btn btn-sm btn-primary">
            Sign Out
          </Link>
        </div>
        {/* end::Link */}
      </div>
    </>
  );
};

export { ErrorPage };
