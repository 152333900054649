import React, { useEffect, useState } from "react";
import "../assets/splash.scss";

const SplashScreen = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating loading time
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="main">
      {loading ? (
        <div className="splash">
          <h1>Welcome to Forte</h1>
          <h2>Loading...</h2>
        </div>
      ) : (
        <h1>Error</h1>
      )}
    </div>
  );
};

export { SplashScreen };
