import React from "react";
import { Link } from "react-router-dom";

const FPSuccess = () => {
  return (
    <>
      {/* <!-- Main Wrapper Starts here --> */}
      <section className="container-fluid">
        <div className="row">
          {/* <!-- Left Wrap Starts here --> */}
          <div className="col-md-6 left-wrap">
            <div className="top">
              <div className="nav-brand">
                <img
                  className="img-fluid"
                  src="../../../media/images/logo-white.png"
                  alt="Forte"
                />
              </div>
              <h1 className="mb-3 mb-md-4">Welcome to forte!</h1>
              <p>
                Congratulations! Your password reset with Forte was successful.
                Welcome back to our community! You can now enjoy seamless access
                to all the features and benefits that Forte has to offer. If you
                have any further questions or need assistance, don't hesitate to
                reach out to our support team. Happy exploring!
              </p>
            </div>
            <img
              className="img-fluid btm-img d-none d-md-inline-block"
              src="../../../media/images/signup-btm-img.svg"
              alt="Signup"
            />
          </div>
          {/* <!-- Left Wrap Ends here --> */}
          {/* <!-- Right Wrap Starts here --> */}
          <div className="col-6 right-wrap">
            <div className="form-wrap">
              <div className="otp-container text-center">
                <img
                  className="img-fluid mb-5"
                  src="../../../media/images/success-ico.svg"
                  alt="Success"
                />
                <h2 className="mb-3">Awesome !</h2>
                <p className="lead">
                  Your account reset & verified successfully
                </p>
              </div>
              <form action="#" className="custom-form">
                <div className="mb-4 text-center">
                  <Link to="/login" className="btn btn-asphalt mt-5 mb-3 px-5">
                    Login
                  </Link>
                  <p className="mb-0">Login & Update your profile</p>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- Right Wrap Starts here --> */}
        </div>
      </section>
      {/* <!-- Main Wrapper Ends here --> */}
    </>
  );
};

export { FPSuccess };
