import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import clsx from "clsx";
import { UserRegister } from "../../core/_models";
import { userRegistration, getRegisterDetail } from "../../core/_requests";
import { AlertContext } from "../alert-context";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { AlertBox } from "../alert-message";
import { registerSchema } from "../../core/_schema";

//INITIAL VALUE
const initialValues = {
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  password: "",
  confirmPassword: "",
  Code: "",
  Message: "",
  Data: {
    id: "",
  },
  country: "",
};

//FUNCTION START
const EmployeeRegister = () => {
  useEffect(() => {
    const getId = localStorage.getItem("uniqueId");
    if (getId) {
      getRegisterDetail(getId).then((res) => {
        if (res.data.Data) {
          formik.setValues({
            firstName: res.data.Data.firstName || "",
            lastName: res.data.Data.lastName || "",
            mobile: res.data.Data.mobile || "",
            email: res.data.Data.email || "",
            password: "",
            confirmPassword: "",
            Code: "",
            Message: "",
            Data: {
              id: "",
            },
            country: "",
          });
        }
      });
    }
  }, []);

  const [showModal, setShowModal] = useState(false);

  // Function to toggle the modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const { showAlert } = useContext(AlertContext);

  //TO GET EMAIL FOR OTP PAGE
  const navigate = useNavigate();
  //SUBMIT START
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const { show, message, type } = useContext(AlertContext);

  const formik = useFormik<UserRegister>({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: async (values, actions) => {
      //GET EMAIL
      sessionStorage.setItem("email", values.email);

      setLoading(true);
      setTimeout(() => {
        userRegistration(values).then((res) => {
          if (res?.Code === "1" || res?.Code === "2") {
            localStorage.setItem("uniqueId", res?.Data?.id);
            navigate("/otp-verification");
          } else {
            showAlert(res?.Message, res?.Code);
          }
        });

        setLoading(false);
      }, 1000);
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [CshowPassword, setShowCPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleCTogglePassword = () => {
    setShowCPassword(!CshowPassword);
  };

  return (
    <>
      {/* <!-- Main Wrapper Starts here --> */}
      <form onSubmit={formik.handleSubmit} className="custom-form">
        <div>
          <div className="mb-4">
            <div className="label-group d-flex">
              <label className="form-label w-50">
                First Name<span className="astrick">*</span>
              </label>
              <label className="form-label w-50">
                Last Name<span className="astrick">*</span>
              </label>
            </div>
            <div className="input-group">
              <input
                type="text"
                aria-label="First name"
                {...formik.getFieldProps("firstName")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.firstName && formik.errors.firstName,
                  },
                  {
                    "is-valid":
                      formik.touched.firstName && !formik.errors.firstName,
                  }
                )}
              />

              <input
                type="text"
                aria-label="Last name"
                {...formik.getFieldProps("lastName")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.lastName && formik.errors.lastName,
                  },
                  {
                    "is-valid":
                      formik.touched.lastName && !formik.errors.lastName,
                  }
                )}
              />
            </div>
            {(formik.touched.firstName || formik.touched.lastName) &&
              ((formik.errors.firstName && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.firstName}</span>
                </div>
              )) ||
                (formik.errors.lastName && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.lastName}</span>
                  </div>
                )))}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Contact Number<span className="astrick">*</span>
            </label>
            <PhoneInput
              copyNumbersOnly={true}
              inputClass="form-control"
              country={"in"}
              enableSearch={true}
              value={formik.values.mobile}
              onChange={(phone) => {
                formik.setFieldValue("mobile", phone);
                formik.setFieldError("mobile", formik.errors.mobile);
              }}
              onBlur={formik.handleBlur("mobile")}
            />

            {formik.touched.mobile && formik.errors.mobile && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.mobile}</span>
              </div>
            )}
            {/* <p className="error-msg">Please fill this field</p> */}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Email address<span className="astrick">*</span>
            </label>
            <input
              type="email"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.email && formik.errors.email,
                },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Password<span className="astrick">*</span>
            </label>
            <div className="input-group with-ico">
              <input
                type={showPassword ? "text" : "password"}
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />

              <span
                className="input-group-text c-pointer"
                onClick={handleTogglePassword}
              >
                <span className="material-symbols-outlined">
                  {showPassword ? "visibility_off" : "visibility"}
                </span>
              </span>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.password}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Confirm Password<span className="astrick">*</span>
            </label>
            <div className="input-group with-ico">
              <input
                type={CshowPassword ? "text" : "password"}
                {...formik.getFieldProps("confirmPassword")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword,
                  },
                  {
                    "is-valid":
                      formik.touched.confirmPassword &&
                      !formik.errors.confirmPassword,
                  }
                )}
              />

              <span
                className="input-group-text c-pointer"
                onClick={handleCTogglePassword}
              >
                <span className="material-symbols-outlined">
                  {CshowPassword ? "visibility_off" : "visibility"}
                </span>
              </span>
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.confirmPassword}</span>
                </div>
              )}
          </div>
          <div className="mb-5">
            <div className="form-check form-check-inline tc">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                checked={agreed}
                onChange={() => setAgreed(!agreed)}
              />

              <label className="form-check-label" htmlFor="inlineCheckbox1">
                I Agree to all{" "}
                <a className="link-button" onClick={toggleModal}>
                  Terms &amp; Conditions
                </a>
              </label>
            </div>
          </div>
          {show && (
            <AlertBox
              type={type == "1" ? "success" : "error"}
              message={message}
            />
          )}
        </div>
        {/* Terms & Conditions Modal */}
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Terms &amp; Conditions</h5>
              </div>
              <div className="modal-body">
                {/* Add your terms and conditions content here */}

                <p>Forte Consulting Applicant and Candidate Privacy Policy</p>
                <p className="mb-0">This policy explains:</p>
                <br></br>
                <ul className="my-list exp-timeline mb-0 fs-16">
                  <li style={{ marginBottom: "9px" }}>
                    What information we collect during our application and
                    recruitment process and why we collect it
                  </li>
                  <li style={{ marginBottom: "9px" }}>
                    How we use that information
                  </li>
                  <li>How to access and update that information.</li>
                </ul>
                <br></br>
                <p>
                  Your use of Forte’s services is governed by any applicable
                  terms and our general Privacy Policy.
                </p>
                <a
                  href={process.env.PUBLIC_URL + "/media/pdf/privacyPolicy.pdf"}
                  download="privacyPolicy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
        ></div>

        <div className="mb-4 text-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || !agreed}
            className="btn btn-asphalt min-w mb-3"
          >
            {!loading && <span className="indicator-label">Signup</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <p className="mb-0">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </form>

      {/* <!-- Main Wrapper Ends here --> */}
    </>
  );
};

export { EmployeeRegister };
