import { useEffect, useState } from "react";

import { EmployerHeader } from "./EmployerHeader";
import { getUserList } from "../profile/profile-form/core/requests";
import { Link } from "react-router-dom";
const EmployerDashboard = () => {
  const [users, setusers] = useState<any>([]);
  const [sort, setsort] = useState("latest");
  const sortBy = (e: any) => {
    setsort(e.target.value);
    getData(e.target.value);
  };
  useEffect(() => {
    getData(sort);
  }, []);
  const getData = (sort: any) => {
    getUserList(sort).then((res) => {
      setusers(res?.data?.Data);
    });
  };
  return (
    <>
      <section className="container-fluid">
        {/* <!-- Header Starts here --> */}
        <EmployerHeader />
        <section className="content-sec row py-4">
          <div className="container-fluid pt-0 pt-md-2">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <h2 className="page-title mb-0">Find Matches</h2>
                </div>
              </div>
              <div className="row find-match-wrap mt-3">
                {/* <div className="col-12 col-lg-4">
                  <div className="left-search-wrap">
                    <div className="form-wrap">
                      <form action="#" className="custom-form">
                        <div className="mb-3">
                          <label className="form-label">Job Title</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Skills</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Technologies</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Experience <small>(in years)</small>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Min"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Max"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Expected Annual Salary
                          </label>
                          <div className="input-group two-input-ico">
                            <span className="input-group-text">$</span>
                            <input
                              type="text"
                              className="form-control ps-0"
                              placeholder="Min"
                            />
                            <span className="input-group-text">$</span>
                            <input
                              type="text"
                              className="form-control ps-0"
                              placeholder="Max"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Education</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Gender</label>
                          <div className="container">
                            <div className="checkbox-wrap row">
                              <div className="col-6 col-md-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="option1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="col-6 col-md-4 form-check form-check-inline  me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox2"
                                  value="option2"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="col-6 col-md-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox3"
                                  value="option3"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox3"
                                >
                                  No Preference
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">VISA Status</label>
                          <select
                            className="form-select form-control"
                            aria-label="VISA Status"
                          >
                            <option selected>Choose...</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Languages</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Location</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Mode of Work</label>
                          <div className="container">
                            <div className="checkbox-wrap row">
                              <div className="col-6 col-md-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mw1"
                                  value="option1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mw1"
                                >
                                  Onsite
                                </label>
                              </div>
                              <div className="col-6 col-md-4 form-check form-check-inline  me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mw2"
                                  value="option2"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mw2"
                                >
                                  Hybrid
                                </label>
                              </div>
                              <div className="col-6 col-md-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mw3"
                                  value="option3"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mw3"
                                >
                                  Remote
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Kind of Role</label>
                          <div className="container">
                            <div className="checkbox-wrap row">
                              <div className="col-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="kr1"
                                  value="option1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="kr1"
                                >
                                  Full-time
                                </label>
                              </div>
                              <div className="col-4 form-check form-check-inline  me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="kr2"
                                  value="option2"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="kr2"
                                >
                                  Contract
                                </label>
                              </div>
                              <div className="col-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="kr3"
                                  value="option3"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="kr3"
                                >
                                  Part Time
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="btm-btn">
                        <button className="btn btn-cancel">Clear All</button>
                        <button className="btn btn-asphalt">Apply</button>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="col-12 col-lg-12 result-listing mt-4 mt-lg-0">
                  <div className="title mb-3">
                    <h4 className="fw-normal mb-0">
                      Search Results {users?.length}
                    </h4>
                    <div className="sort-by">
                      <label className="form-label">Sort by:</label>
                      <select
                        className="form-select"
                        onChange={(event) => sortBy(event)}
                      >
                        <option selected value="latest">
                          Latest
                        </option>
                        <option value="oldest">Oldest</option>
                      </select>
                    </div>
                  </div>
                  <div className="listing-wrap">
                    {users.map((item: any, i: any) => {
                      return (
                        <div className="mb-3">
                          <div className="candidate-snippet">
                            <div className="head">
                              <div className="avatar-encl">
                                <div className="avatar-img">
                                  <img
                                    className="img-fluid"
                                    src={item?.profile}
                                    alt="Candidate Name"
                                  />
                                </div>
                                <div className="avatar-detail">
                                  <h5 className="mb-1">
                                    {item?.firstName + " " + item?.lastName}
                                  </h5>
                                  <p className="mb-0 fs-13 text-gray">
                                    Full Stack Developer
                                  </p>
                                </div>
                              </div>
                              <div className="btn-encl">
                                <Link
                                  className="btn btn-asphalt btn-gray me-2"
                                  to={`/employee-profile/${item?.id}`}
                                >
                                  View Profile
                                </Link>
                                <a
                                  className="btn btn-asphalt linkedin-btn"
                                  href={item?.linkedInProfile}
                                  target="_blank"
                                >
                                  <img
                                    className="img-fluid me-2"
                                    src="../../../media/images/linkedin-white-ico.svg"
                                    alt="LinkedIn"
                                  />
                                  LinkedIn Profile
                                </a>
                              </div>
                            </div>
                            <div className="cont">
                              <div className="row">
                                {item?.experienceYears === "null" ? (
                                  <>
                                    <div className="col-6 col-md-3 mb-3">
                                      <p className="fs-11 text-gray mb-1">
                                        Fresher
                                      </p>
                                      <p className="fs-13 mb-0">0 Years</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-6 col-md-3 mb-3">
                                      <p className="fs-11 text-gray mb-1">
                                        Experience
                                      </p>
                                      <p className="fs-13 mb-0">
                                        {item?.experienceYears} Years
                                      </p>
                                    </div>
                                  </>
                                )}

                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Expected Annual Salary
                                  </p>
                                  <p className="fs-13 mb-0">
                                    $ {item?.expectedSalary}
                                  </p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Education
                                  </p>
                                  <p className="fs-13 mb-0">
                                    {item?.education[0]?.certificateName}
                                  </p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Visa Status
                                  </p>
                                  <p className="fs-13 mb-0">
                                    {item?.visaStatus
                                      ? "US Citizen"
                                      : "Not US Citizen"}
                                  </p>
                                </div>
                                {item?.technologies.length > 0 ? (
                                  <>
                                    <div className="col-12">
                                      <p className="fs-11 text-gray mb-1">
                                        Technologies
                                      </p>
                                      {item?.technologies?.map(
                                        (items: any, i: any) => {
                                          return (
                                            <span className="badge tech">
                                              {items?.name}
                                            </span>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p className="fs-11 text-gray mb-1">
                                      Technologies
                                    </p>
                                    <span className="">-</span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <nav aria-label="pagination">
                    <ul className="pagination justify-content-md-end justify-content-center">
                      <li className="page-item disabled">
                        <a
                          className="page-link btn-asphalt btn me-2"
                          href="#"
                          tabIndex={-1}
                          aria-disabled="true"
                        >
                          Previous
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="ms-2 page-link btn-asphalt btn" href="#">
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export { EmployerDashboard };
