import React, { useContext, useState } from "react";
import { AlertContext } from "../component/alert-context";
import { AlertBox } from "./alert-message";
import { EmployeeRegister } from "./employee/EmployeeRegister";
import { EmployerRegister } from "./employer/EmployerRegister";
//LOGIN SCHEMA

//FUNCTION START
const Registration = () => {
  const [activeTab, setActiveTab] = useState("employee");
  const { show, message, type } = useContext(AlertContext);
  const handleButtonClick = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <>
      {/* <!-- Main Wrapper Starts here --> */}
      <section className="container-fluid">
        <div className="row">
          {/* <!-- Left Wrap Starts here --> */}
          <div className="col-md-6 left-wrap">
            <div className="top">
              <div className="nav-brand">
                <img
                  className="img-fluid"
                  src="../../../media/images/logo-white.png"
                  alt="Forte"
                />
              </div>
              <h1 className="mb-3 mb-md-4">Welcome to forte!</h1>
              <p>
                Welcome to Forte! We're excited to have you join our community.
                Please take a moment to register and create your account. Fill
                out the following information
              </p>
            </div>
            <img
              className="img-fluid btm-img d-none d-md-inline-block"
              src="../../../media/images/signup-btm-img.svg"
              alt="Signup"
            />
          </div>
          {/* <!-- Left Wrap Ends here --> */}
          {/* <!-- Right Wrap Starts here --> */}

          <div className="col-6 right-wrap">
            <div className="form-wrap">
              <h2 className="mb-md-5 mb-4">Create an Account</h2>

              <div className="row mb-4">
                <div className="col-12">
                  <h5>You’re a</h5>
                  <div className="role">
                    <p
                      className={`mb-0 ${
                        activeTab === "employee" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("employee")}
                      title="Are you looking for a job"
                    >
                      <span className="material-symbols-outlined">
                        account_box
                      </span>
                      Employee
                    </p>
                    <p
                      className={`mb-0 ${
                        activeTab === "employer" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("employer")}
                      title="Are you currently looking to hire an employee?"
                    >
                      <span className="material-symbols-outlined">
                        person_search
                      </span>
                      Employer
                    </p>
                  </div>
                </div>
              </div>
              {activeTab === "employee" ? (
                <EmployeeRegister />
              ) : (
                <EmployerRegister />
              )}
            </div>
          </div>
          {/* <!-- Right Wrap Starts here --> */}
        </div>
      </section>
      {/* <!-- Main Wrapper Ends here --> */}
    </>
  );
};

export { Registration };
