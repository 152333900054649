import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";
import { SplashScreen } from "../../features/SplashScreen";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getUserByToken } from "./_requests";

type WithChildren = {
  children?: ReactNode;
};
type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const token = localStorage.getItem("rakyat_auth_detail") || "";
const user = token ? JSON.parse(token) : undefined;
const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  // currentUser: JSON.parse(localStorage.getItem('rakyat_auth_detail') || ''),
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(user);
  const saveAuth = (auth: AuthModel | undefined) => {
    //debugger
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth ? auth : user);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    localStorage.removeItem("rakyat_auth_detail");
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken);
          if (data) {
            setCurrentUser(data);
          }
        }
      } catch (error) {
        // console.error(error)
        if (!didRequest.current) {
          // logout()
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.api_token) {
      requestUser(auth.api_token);
    } else {
      // logout()
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <SplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
