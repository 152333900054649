import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
export const PrivacyPolicy = () => {
  const [showPdf, setShowPdf] = useState(false);
  const handleDownloadPdf = () => {
    const pdfBase64 = "base64_encoded_pdf_data_here";

    // Update the state to show the PDF
    setShowPdf(true);

    // Convert the base64 data to Uint8Array
    const bytes = new Uint8Array(
      atob(pdfBase64)
        .split("")
        .map((char) => char.charCodeAt(0))
    );

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, "_blank");
  };
  return (
    <div>
      <h2>Applicant Data Privacy</h2>
      <p>Forte Consulting Applicant and Candidate Privacy Policy</p>
      <p>This policy explains:</p>
      <ol className="my-list">
        <li>
          What information we collect during our application and recruitment
          process and why we collect it
        </li>
        <li>How we use that information</li>
        <li>How to access and update that information.</li>
      </ol>
      <p>
        Your use of Forte’s services is governed by any applicable terms and our
        general Privacy Policy.
      </p>
      <button onClick={handleDownloadPdf}>Download PDF</button>
      {showPdf && (
        <embed
          src="../../../public/media/pdf/privacyPolicy.pdf"
          width="100%"
          height="600px"
          type="application/pdf"
        />
      )}
    </div>
  );
};
