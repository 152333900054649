import { Route, Routes } from "react-router-dom";
import { Login } from "./component/Login";

const AuthPage = () => (
  <Routes>
    <Route>
      <Route path="login" element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
